.makerlab-logos-wrapper {

    & > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        width: 100vw;
        height: 170px;
        margin: 0;
        padding: 0;

        @media only screen and (max-width: 960px) {
            height: 100px;
        }
    }

    .makerlab-logos-item {
        margin-right: 90px;
    }

    img {
        width: 170px;
        mix-blend-mode: difference; // temp

        @media only screen and (max-width: 960px) {
            width: 80px;
        }
    }
}