.makerlab-emailForm {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 960px) {
        width: 100%;
        margin-top: 15px;
        margin-left: 0;
    }

    &[data-ty="true"] {
        input,
        select,
        textarea {
            opacity: 0.5;
        }

        button[type="submit"] {
            width: 100%;
            color: #fff;
            border: 1px solid $mklprimaryblack;
            background: $mklprimaryblack;
        }
    }

    &-group {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 14px;

        input:not(:last-child) {
            margin-right: 14px;
        }

        @media screen and (max-width: 960px) {
            flex-direction: column;
            margin-bottom: 0;
        }

        .fieldset-region {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            margin-bottom: 20px;

            label {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                padding-left: 0;
                margin-right: 90px;
                margin-bottom: 0;
                cursor: pointer;
                font-size: 22px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                
                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;

                    &:checked ~ .checkmark {
                        background-color: $black;
                    }
                }

                .checkmark {
                    position: relative;
                    display: block;
                    height: 20px;
                    width: 20px;
                    margin-right: 15px;
                    background-color: transparent;
                    border: 1px solid $black;
                    border-radius: 50%;
                    will-change: background-color;
                    transition: background-color 0.3s ease;
                }
            }

            @media screen and (max-width: 960px) {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                margin-right: 0;
                margin-bottom: 20px;

                label {
                    margin-bottom: 10px;
                }
            }
        }
    }

    h4 {
        font-family: "Avenir", sans-serif;
        font-size: 30px;
        line-height: 34px;
        font-weight: 400;
        color: $mklprimaryblack;
        margin-bottom: 30px;

        @media screen and (max-width: 960px) {
            font-size: 20px;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    input:not([type='radio']),
    textarea {
        display: block;
        width: 100%;
        margin: 40px 0;
        padding: 12px 20px;
        font-family: "Avenir", sans-serif;
        font-size: 20px;
        font-optical-sizing: auto;
        font-weight: 400;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 120px;
        color: rgba(0, 0, 0, 0.5);
        box-shadow: none;
        background: transparent;
        outline: none;

        &::placeholder {
            color: rgba(0, 0, 0, 0.8);
        }

        @media screen and (max-width: 960px) {
            width: 100%;
            margin-top: 20px;
            margin-bottom: 6px;
            padding: 6px 20px 6px 25px;
            font-size: 14px;
            border: 1px solid rgba(0, 0, 0, 0.8);
            color: rgba(0, 0, 0, 0.8);
        }
    }

    textarea {
        margin: 0 0 40px 0;
        resize: none;
    }

    button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 125px;
        height: 40px;
        font-family: "Avenir", sans-serif;
        font-size: 20px;
        line-height: 38px;
        font-optical-sizing: auto;
        font-weight: 400;
        color: $black;
        border-radius: 62px;
        border: 1px solid $black;
        background: transparent;
        transition: all 0.3s ease;

        @media screen and (max-width: 960px) {
            margin-top: 16px;
            padding: 12px 36px;
            font-size: 14px;
        }

        &:hover {
            background: $blue;
            color: $white;
            border-color: $white;
        }
    }
}