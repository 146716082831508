.makerlab-map-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: $blue;

    h3 {
        margin-top: 54px;
        font-family: 'Avenir', sans-serif;
        font-size: 40px;
        line-height: 80px;
        font-weight: 400;
        color: $white;

        @media only screen and (max-width: 960px) {
            font-size: 34px;
            line-height: 40px;
        }
    }

    .makerlab-map-svg {
        display: block;
        width: 72%;        
        margin-top: 30px;

        @media only screen and (max-width: 960px) {
            width: 90%;
            margin-top: 0;
        }

        svg {
            display: block;
            width: 100%;
            height: 100%;
            max-height: 50vh;
            object-fit: contain;

            g {
                transition: opacity 0.5s ease;
            }

            #m-g-map {
                opacity: 0.8;
            }

            .g-group {
                // opacity: 0;
                will-change: opacity;
            }
        }

        &[data-active="true"] {
            svg {
                #m-g-map {
                    opacity: 0.6;
                }

                // g:not(#m-g-map) {
                //     opacity: 1;
                // }
            }
        }
    }

    .makerlab-map-texts {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        width: 100%;
        margin-top: 40px;
        padding-bottom: 30px;

        @media only screen and (max-width: 960px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            padding-bottom: 0;
        }

        &[data-show="true"] {
            .makerlab-map-text {
                transform: translate3d(0, 0, 0);
                opacity: 1;

                &:nth-child(0) {
                    transition-delay: 0;
                }

                &:nth-child(1) {
                    transition-delay: 0.2s;
                }

                &:nth-child(2) {
                    transition-delay: 0.3s;
                }
            }
        }

        .makerlab-map-text {
            display: block;
            max-width: 330px;
            transform: translate3d(0, 20px, 0);
            opacity: 0;
            transition: all 0.5s ease 0.5s;
            will-change: opacity, transform;
        }

        h4,
        p {
            font-family: 'Avenir', sans-serif;
            font-size: 20px;
            line-height: 24px;
            font-weight: 400;

            @media only screen and (max-width: 960px) {
                font-size: 16px;
                line-height: 20px;
            }
        }

        h4 {
            color: $volt;
        }

        p {
            color: $white;

            @media only screen and (max-width: 960px) {
                    margin-bottom: 30px;
            }
        }
    }
}