@import "variables";

.makerlab {
    &-spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100dvh;
    }

    &-scroll-container {
        height: 100dvh;
        overflow: auto;
        scroll-snap-type: y mandatory;
        scroll-snap-stop: always;
    }

    &-logo {
        display: block;
        width: 164px;
        height: auto;

        &--desktop {
            @media only screen and (max-width: 960px) {
                width: 100px;
            }
        }

        &--large {
            width: 76vw;
            fill: $white;
        }
    }

    &-section {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
        scroll-snap-align: start;
        scroll-snap-stop: always;
        width: 100vw;
        height: 100dvh;

        &-intro {
            position: relative;
            overflow: hidden;

            .makerlab-column {
                width: 100%;

                video {
                    display: block;
                    width: 100%;
                    height: 100dvh;
                    margin-top: 60px;
                    object-fit: cover;

                    //@media only screen and (max-width: 960px) {
                    //    height: 100dvh;
                    //    object-fit: cover;
                    //}
                }
            }

            @media only screen and (max-width: 960px) {
                height: 100dvh;
            }

            .makerlab-scrolltobottom {
                position: absolute;
                bottom: 20px;
                left: 0;
                right: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100px;
                margin: 0 auto;
                opacity: 0;
                transform: translate3d(0,-20px,0);
                transition: all 0.3s ease;

                &[data-show="true"] {
                    opacity: 1;
                    transform: translate3d(0,0,0);
                }

                span {
                    font-family: 'Avenir', sans-serif;
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: bold;
                    color: #fff;
                    text-transform: uppercase;
                    text-align: center;
                }

                svg {
                    display: block;
                    width: 60px;
                    margin-top: 25px;
                }
            }
        }

        &-clients {
            position: relative;

            .makerlab-column {
                background: black;
            }

            .makerlab-clients-wrapper {
                transition: all 0.6s ease 0.4s;
            }

            .clients-text {
                transition: all 0.6s ease 0.2s;

                // &::before {
                //     content: "";
                //     position: absolute;
                //     top: 30px;
                //     left: calc(46vw/2*-1);
                //     width: 200px;
                //     height: 1px;
                //     background: $white;

                //     @media only screen and (max-width: 960px) {
                //         top: 18px;
                //         left: -55vw;
                //     }
                // }
            }

            &[data-show="false"] {
                .clients-text,
                .makerlab-clients-wrapper {
                    opacity: 0;
                    transform: translate3d(0,50px,0);
                }
            }

            &[data-show="true"] {
                .clients-text,
                .makerlab-clients-wrapper {
                    opacity: 1;
                    transform: translate3d(0,0,0);
                }
            }
        }

        &-microagencies {
            @media only screen and (max-width: 960px) {
                // padding-top: 20px;
                // padding-bottom: 20px;

                h3 {
                    width: 84vw !important;
                }
            }

            .makerlab-column {
                // background: black;
                background: #c2c2c2;
            }

            .clients-text {
                transition: all 0.6s ease 0.2s;
            }

            &[data-show="false"] {
                .clients-text {
                    opacity: 0;
                    transform: translate3d(0,50px,0);
                }
            }

            &[data-show="true"] {
                .clients-text {
                    opacity: 1;
                    transform: translate3d(0,0,0);
                }
            }

            .makerlab-carousel-wrapper {
                justify-content: stretch;
                height: 100%;
            }

            .makerlab-carousel-item {
                align-self: stretch;

                img {
                    margin-bottom: -20px;

                    @media only screen and (max-width: 960px) {
                        margin-bottom: -10px;
                    }
                }
            }
        }

        &-about {
            @media only screen and (max-width: 960px) {
                // padding-top: 50px;
            }

            .makerlab-column {
                background: black;
            }
        
            &-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                width: 54vw;

                @media only screen and (max-width: 960px) {
                    width: 80vw;
                    margin-top: -100px;
                }

                h4 {
                    width: 54vw;
                    max-width: 570px;
                    font-family: "Lexend", sans-serif;
                    font-size: 30px;
                    font-optical-sizing: auto;
                    font-weight: 400;
                    line-height: 40px;

                    // text-transform: uppercase;
                    color: $white;
                    transition: all 0.6s ease 0.2s;

                    @media only screen and (max-width: 960px) {
                        width: 70vw;
                        max-width: 320px;
                        font-size: 26px;
                        line-height: 30px;
                    }
                }

                h5 {
                    display: block;
                    width: 54vw;
                    max-width: 570px;
                    margin: 30px 0 72px;
                    font-family: "Lexend", sans-serif;
                    font-size: 24px;
                    font-optical-sizing: auto;
                    font-weight: 300;
                    line-height: 34px;

                    color: $white;
                    transition: all 0.6s ease 0.4s;

                    @media only screen and (max-width: 960px) {
                        width: 70vw;
                        max-width: 320px;
                        margin: 30px 0 18px;
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
            }

            &-skills {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin: 0;
                padding: 0;
                list-style: none;

                @media only screen and (max-width: 960px) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                &-item {
                    display: block;
                    padding: 15px 0;
                    border-top: 1px solid $white;
                    border-bottom: 1px solid $white;
                    transition: all 0.6s ease;

                    &:not(:last-child) {
                        margin-right: 24px;
                    }

                    @media only screen and (max-width: 960px) {
                        width: 80%;
                        margin-right: 0 !important;
                        // margin-bottom: 16px;
                    }

                    $animation-delay-step: 0.2s; // Adjust the step as needed
                    @function calculate-animation-delay($index) {
                        @return $index * $animation-delay-step;
                    }

                    @for $i from 1 through 5 {
                        &:nth-child(#{$i}) {
                          transition-delay: calculate-animation-delay($i);
                        }
                    }

                    span {
                        font-family: "Lexend", sans-serif;
                        font-size: 40px;
                        font-optical-sizing: auto;
                        font-weight: 700;
                        line-height: 40px;
    
                        text-transform: uppercase;
                        color: $white;

                        @media only screen and (max-width: 960px) {
                            font-size: 22px;
                            line-height: 26px;
                        }
                    }
                }
            }

            &[data-show="false"] {
                h4,
                h5,
                li {
                    opacity: 0;
                    transform: translate3d(0,50px,0);
                }
            }

            &[data-show="true"] {
                h4,
                h5,
                li {
                    opacity: 1;
                    transform: translate3d(0,0,0);
                }
            }
        }

        &-champions {
            @media only screen and (max-width: 960px) {
                height: calc(var(--vh, 1vh) * 100 - 70px);
                padding-top: 0;
            }

            .makerlab-column {
                justify-content: flex-start;
                background: $black;
                overflow: visible;

                @media only screen and (max-width: 960px) {
                    height: 100%;
                }

                &--right {
                    position: relative;
                    width: 100%;
                    z-index: 5;

                    @media only screen and (max-width: 960px) {
                        height: 100%;
                        margin-top: 70px;
                    }
                }
            }

            &[data-show="false"] {
                .makerlab-section-champions-top span {
                    opacity: 0;
                    transform: translate3d(0,50px,0);
                    transition-delay: 0s;
                }
            }

            &[data-show="true"] {
                .makerlab-section-champions-top span {
                    opacity: 1;
                    transform: translate3d(0,0,0);
                    transition-delay: 0s;
                }
            }
            
            &-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 100vh;

                @media only screen and (max-width: 960px) {
                    height: 100%;
                }
            }

            &-top {
                display: flex;
                flex-direction: row;
                justify-content: center;
                // align-items: center;
                text-align: center;
                
                // flex-direction: row;
                // justify-content: center;
                // align-items: center;
                width: 100%;
                height: 100%;
                padding: 0;

                @media only screen and (max-width: 960px) {
                    padding: 80px 0 0;
                    display: block;
                    
                }

                span {
                    display: inline-block;
                    max-width: 100%;
                    font-family: "Lexend", sans-serif;
                    font-size: 30px;
                    font-optical-sizing: auto;
                    font-weight: 300;
                    line-height: 40px;

                    color: $white;
                    // text-transform: uppercase;
                    transition: all 0.6s ease 0.2s;
                    // white-space: nowrap;

                    @media only screen and (max-width: 960px) {
                        width: 70vw;
                        max-width: 320px;
                        font-size: 32px;
                        line-height: 36px;
                    }

                    &.bold-richtext {
                        font-weight: 500;
                    }
                }
            }
        }

        &-insights {
            .makerlab-column {
                background: $blue;
            }

            @media only screen and (max-width: 960px) {
                h3 {
                    width: 76vw !important;
                }
            }

            .clients-text {
                transition: all 0.6s ease 0.2s;

                @media only screen and (max-width: 960px) {
                    span {
                        font-size: 28px;
                        line-height: 32px;
                    }
                }

                // &::before {
                //     content: "";
                //     position: absolute;
                //     top: 30px;
                //     left: calc(46vw/2*-1);
                //     width: 200px;
                //     height: 1px;
                //     background: $white;

                //     @media only screen and (max-width: 960px) {
                //         top: 18px;
                //         left: -55vw;
                //     }
                // }
            }

            .makerlab-section-insights-number {
                transition: all 0.6s ease 0.4s;
            }

            .makerlab-section-insights-text {
                transition: all 0.6s ease 0.5s;
            }

            .makerlab-section-insights-wrapper > a {
                transition: all 0.6s ease 0.6s;
            }

            &[data-show="false"] {
                .clients-text,
                .makerlab-section-insights-number,
                .makerlab-section-insights-text,
                .makerlab-section-insights-wrapper > a {
                    opacity: 0;
                    transform: translate3d(0,50px,0);
                }
            }

            &[data-show="true"] {
                .clients-text,
                .makerlab-section-insights-number,
                .makerlab-section-insights-text,
                .makerlab-section-insights-wrapper > a {
                    opacity: 1;
                    transform: translate3d(0,0,0);
                }
            }

            &-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 54vw;
                max-width: 570px;
                margin-top: 75px;

                @media only screen and (max-width: 960px) {
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    width: 76vw;
                    margin-top: 35px;
                }
            }

            &-number {
                display: block;
                width: 194px;
                font-family: "Lexend", sans-serif;
                font-size: 70px;
                font-optical-sizing: auto;
                font-weight: 500;
                line-height: 54px;
                color: $white;

                @media only screen and (max-width: 960px) {
                    font-size: 80px;
                }
            }

            &-text {
                max-width: 160px;
                margin-left: 24px;
                font-family: "Lexend", sans-serif;
                font-size: 20px;
                line-height: 24px;
                font-optical-sizing: auto;
                font-weight: 300;
                color: $white;

                @media only screen and (max-width: 960px) {
                    max-width: 100%;
                    margin-left: 4px;
                    margin-top: 20px;
                    font-size: 18px;
                    line-height: 20px;
                }
            }

            &-link {
                display: block;
                width: 180px;
                padding: 15px 0;
                font-family: "Lexend", sans-serif;
                font-size: 14px;
                font-optical-sizing: auto;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                text-transform: uppercase;
                color: $black;
                background: $white;
                border-radius: 27px;
                transition: all 0.3s ease;
                transition-delay: 0s !important;

                @media only screen and (max-width: 960px) {
                    margin-top: 35px;
                }

                &:hover {
                    background: #D6FE00;
                }
            }
        }

        &-contact {
            .makerlab-column {
                justify-content: flex-end;
                align-items: center;
                padding-bottom: 50px;
                background: $black;

                @media only screen and (max-width: 960px) {
                    padding-bottom: 120px;
                }
            }

            &-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;

                @media only screen and (max-width: 960px) {
                    flex-direction: column;

                    & > div:first-of-type {
                        order: 2;
                    }
                }

                &-text {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    width: calc(50% - 50px);
                    margin-left: 50px;

                    @media only screen and (max-width: 960px) {
                        width: 100%;
                        order: 1;
                    }

                    span, 
                    a {
                        font-family: "Lexend", sans-serif;
                        font-size: 17px;
                        font-optical-sizing: auto;
                        font-weight: 300;
                        line-height: 27px;
                        color: $white;

                        @media only screen and (max-width: 960px) {
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }

                    span {
                        max-width: 50%;
                        margin-bottom: 60px;

                        @media only screen and (max-width: 960px) {
                            max-width: 100%;
                            margin-bottom: 15px;
                        }
                    }

                    a {
                        color: #AFAFAF;
                        transition: color 0.3s ease;

                        &:nth-of-type(2) {
                            margin-bottom: 10px;
                        }

                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    &-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100dvh;
        overflow: hidden;

        @media only screen and (max-width: 960px) {
            height: calc(var(--vh, 1vh) * 100);
        }

        &--left {
            position: fixed;
            top: 0;
            left: 0;
            justify-content: space-between;
            width: 20%;
            padding: 50px 0 58px;
            background: $white;
            transition: background 0.6s ease;
            pointer-events: none;

            @media only screen and (max-width: 960px) {
                position: absolute;
                top: 0;
                flex-direction: row;
                width: 100%;
                height: 70px;
                padding: 0 20px;
                background: $white;
                z-index: 20;

                .makerlab-logo {
                    width: 90px;
                }

                &-bottom {
                    width: auto !important;

                    svg {
                        display: none;
                    }
                }
            }

            &-bottom {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 86%;

                svg {
                    max-width: 50px;
                }

                span + span {
                    color: rgba(0,0,0,0.5);
                }
            }

            h2 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                opacity: 1;
                transform: translate3d(0,0,0);
                transition: all 0.5s ease;

                span {
                    font-family: "Lexend", sans-serif;
                    font-size: 12px;
                    font-optical-sizing: auto;
                    font-weight: 400;
                    line-height: 16px;

                    @media only screen and (max-width: 960px) {
                        font-size: 12px;
                    }
                }
            }

            .makerlab-logo {
                display: flex;
                flex-direction: row;
                opacity: 1;
                max-height: 176px;     
            }

            .makerlab-bird {
                transform: translate3d(0,0,0) scale(1);
                transition: transform 0.7s cubic-bezier(0.22, 1, 0.36, 1);
            }

            @media only screen and (min-width: 960px) {
                &[data-secondarySidebar="true"] {
                    background: $volt;
                }

                &[data-currentview="insights"],
                &[data-currentview="champions"],
                &[data-secondarySidebar="true"][data-currentview="champions"] {
                    transition-duration: 0s;
                }

                &[data-secondarySidebar="false"][data-currentview="insights"] {
                    transition-duration: 0s;
                    background: $white;

                    .makerlab-logo,
                    span {
                        display: none;
                    }
                    
                    .makerlab-bird {
                        transform: translate3d(0,-85vh,0) ;
                    }
                }

            }

        }

        &--right {
            justify-content: center;
            width: 80%;

            @media only screen and (max-width: 960px) {
                width: 100%;
            }

            img,
            video {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center 75%;
            }

            // user for: 
            // clients, microagencies
            h3 {
                position: relative;
                display: block;
                width: 54vw;
                max-width: 570px;
                margin: 0 auto;

                @media only screen and (max-width: 960px) {
                    width: 80vw;
                    max-width: 400px;
                }

                span {
                    font-family: "Lexend", sans-serif;
                    font-size: 30px;
                    font-optical-sizing: auto;
                    font-weight: 300;
                    line-height: 40px;
                    color: $white;

                    @media only screen and (max-width: 960px) {
                        font-size: 26px;
                        line-height: 30px;
                    }

                    &.bold-richtext {
                        font-weight: 400;
                    }
                }
            }
        }
    }    

    &-clients-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* Creates 4 columns with equal width */
        gap: 0; 
        width: 54vw;
        max-width: 570px;
        margin: 80px 0 0 0;
        padding: 0;
        list-style: none;

        @media only screen and (max-width: 960px) {
            width: 80vw;
            margin: 40px 0 0 0;
            
            li {
                margin-right: 20px;
            }
        }

        li {
            flex: 1 0 calc(25% - 20px); /* Four items per row, minus the gap */
        }

        img {
            display: block;
            max-width: 100px;
            object-fit: contain;
        }
    }

    &-carousel {
        &-container {
            display: block;
            width: 100%;
            margin-top: 120px;

            @media only screen and (max-width: 960px) {
                margin-top: 30px;
            }
        }

        &-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0;
            padding: 0;
            overflow: auto;
            transition: transform 0.3s ease;
            // scroll-behavior: smooth;

            &:hover {
                cursor: grab;
            }
            
            &.active {
                cursor: grabbing;
                cursor: -webkit-grabbing;
                transform: scale(1.01);
            }

            &::-webkit-scrollbar {
                opacity: 0;
            }

            &[data-darkmode="false"] {
                .makerlab-carousel-item {
                    background: $white;
                }

                .makerlab-carousel-item-text {
                    color: $black;
                }
            }
        }

        &-item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;

            flex: 0 0 auto; /* Prevent items from shrinking */
            
            width: 276px;
            // height: 404px;
            height: 100%;
            list-style: none;
            background: $grey;
            overflow: hidden;

            @media only screen and (max-width: 960px) {
                width: 210px;
                height: 320px;
            }

            &:not(:last-child) {
                margin-right: 5px;
            }

            img {
                display: flex;
                justify-self: flex-start;
                align-items: flex-start;
                width: auto;
                height: 100px;
                object-fit: contain;
                margin-bottom: 10px;

                @media only screen and (max-width: 960px) {
                    height: 76px;
                }
            }

            &-index {
                position: relative;
                display: block;
                margin-top: 24px;
                margin-left: 56px;
                font-family: "Lexend", sans-serif;
                font-size: 60px;
                font-optical-sizing: auto;
                font-weight: 500;
                line-height: 60px;
                color: $white;

                @media only screen and (max-width: 960px) {
                    margin-top: 18px;
                    margin-left: 32px;
                    font-size: 42px;
                }

                // &::before {
                //     content: "";
                //     position: absolute;
                //     top: 50%;
                //     left: -56px;
                //     width: 32px;
                //     height: 2px;
                //     background: $white;
                // }
            }

            &-text {
                font-family: "Lexend", sans-serif;
                font-size: 15px;
                font-optical-sizing: auto;
                font-weight: 300;
                line-height: 18px;
                color: $white;

                @media only screen and (max-width: 960px) {
                    font-size: 14px;
                    line-height: 16px;
                }
            }

            &--bottom {
                display: block;
                padding: 0 26px 20px;

                @media only screen and (max-width: 960px) {
                    padding: 0 16px 20px;
                }
            }
        }
    }
}