.makerlab-contact-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 720px;
    max-height: 100dvh;
    background-color: $volt;

    @media only screen and (max-width: 960px) {
        height: 100dvh;
    }

    .makerlab-contact-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        max-width: 640px;
        margin: 0 auto;
        padding: 0;

        @media only screen and (max-width: 960px) {
            max-width: 90%;
        }
    }

    h3 {
        font-family: 'Avenir', sans-serif;
        font-size: 40px;
        line-height: 24px;
        font-weight: 400;
        color: $mklprimaryblack;

        @media screen and (max-width: 960px) {
            font-size: 34px;
            line-height: 40px;
        }
    }
}