.makerlab-section-champions-wrapper {
    display: block;
    height: 100dvh;
    overflow: hidden;
    white-space: nowrap;

    @media only screen and (max-width: 960px) {
        height: calc(var(--vh, 1vh) * 100);
        //height: 600px;
    }
}

.makerlab-carousel-container {
    width: 100vw;
    margin-top: 0;
    z-index: 10;

    display: inline-block !important;

    @media only screen and (max-width: 960px) {
        margin-top: 0;
    }

    .makerlab-carousel-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100dvh;
        margin: 0;
        padding: 0;
        overflow: visible;
        transition: none !important;

        @media only screen and (max-width: 960px) {
            height: calc(var(--vh, 1vh) * 100);
            //height: 600px;
        }

        &:hover {
            cursor: default;
        }

        &::-webkit-scrollbar {
            opacity: 0;
        }
    }

    .makerlab-carousel-item {
        position: relative;
        width: 33vw;
        min-width: 470px;
        height: 100dvh;
        max-height: 100%;
        margin: 0;
        padding: 200px 0 0 0;
        background: transparent;

        @media only screen and (max-width: 960px) {
            width: 100vw;
            min-width: 100vw;
            height: 100dvh;
            padding: 0;
        }

        &:hover {
            // border: 4px solid $black;

            .carousel-overlay {
                opacity: 0;
                // background: rgba(24, 25, 23, 0);
                // outline: 4px solid $black;
            }
        }

        .carousel-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(24, 25, 23, 0.7);
            opacity: 1;
            z-index: 1;
            transition: opacity 0.5s ease;
            // will-change: all;
            pointer-events: none;

            @media only screen and (max-width: 960px) {
                display: none;
            }
        }

        &--bottom {
            width: 100%;
            height: 100%;
            padding: 0;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;

                @media only screen and (max-width: 960px) {
                    max-height: 100%;
                }
            }

            .makerlab-carousel-item-text-column {
                position: absolute;
                top: 174px;
                left: 50px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                padding-left: 30px;

                @media only screen and (max-width: 960px) {
                    top: 70px;
                    left: 0;
                }

                &[data-dark="true"] {
                    .makerlab-carousel-item-text {
                        color: $black;
                    }
                }

                a {
                    svg {
                        display: block;
                        width: 32px;
                        margin-top: 16px;
                    }
                }
            }

            .makerlab-carousel-item-text {
                font-family: 'Avenir', sans-serif;
                font-size: 20px;
                line-height: 28px;
                font-weight: 400;
                color: $white;

                &--bold {
                    margin-right: 15px;
                    font-size: 30px;
                    line-height: 38px;
                }
            }
        }
    }
}