footer {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 600px;
    background-color: $black;

    @media only screen and (max-width: 960px) {
        min-height: auto;
        padding-bottom: 40px;
    }

    .makerlab-footer {
        &-top {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            width: calc(100% - 90px);
            margin: 52px auto 0;

            @media only screen and (max-width: 960px) {
                flex-direction: column;
                width: 100%;
                margin: 24px auto 0;
                padding: 0 22px;
            }

            .makerlab-miroma {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: 60%;
                margin-top: 48px;

                @media only screen and (max-width: 960px) {
                    width: 100%;
                    margin-top: 0;
                }

                span {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    font-family: 'Avenir', sans-serif;
                    font-size: 40px;
                    line-height: 48px;
                    font-weight: 400;
                    color: $white;

                    svg {
                        display: block;
                        width: 330px;
                        margin-left: 12px;
                    }

                    @media only screen and (max-width: 960px) {
                        font-size: 20px;
                        line-height: 40px;

                        svg {
                            width: 150px;
                        }
                    }
                }

                small {
                    display: block;
                    width: 100%;
                    font-family: 'Avenir', sans-serif;
                    font-size: 18px;
                    line-height: 48px;
                    font-weight: 400;
                    color: $white;

                    @media only screen and (max-width: 960px) {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
        }

        &-nav {
           display: block;
           margin: auto 0 0 auto;

            @media only screen and (max-width: 960px) {
                margin: 40px 0 0;
            }
           
           ul {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    margin: 0;
                    padding: 0;

                    &:hover {
                        span,
                        a {
                            color: $white;
                            border-bottom: 1px solid $volt;
                            cursor: pointer;
                        }
                    }

                    &:last-child {
                        a {
                            margin-bottom: 0;
                        }
                    }

                    span,
                    a {
                        display: block;
                        margin: 10px 0;
                        font-family: 'Avenir', sans-serif;
                        font-size: 20px;
                        line-height: 24px;
                        font-weight: 400;
                        color: rgba(255, 255, 255, 0.5);
                        border-bottom: 1px solid transparent;

                        @media only screen and (max-width: 960px) {
                            margin: 8px 0;
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }
                }
           }
        }

        &-backtotop {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: auto 0 0 auto;

            font-family: 'Avenir', sans-serif;
            font-size: 20px;
            line-height: 24px;
            font-weight: 400;
            color: $white;

            svg {
                display: block;
                width: 30px;
                margin-bottom: 24px;
            }

            @media only screen and (max-width: 960px) {
                margin: -56px 0 0 auto;
                font-size: 16px;
                line-height: 20px;

                svg {
                    width: 20px;
                    margin-bottom: 16px;
                }
            }

            &:hover {
                cursor: pointer;
            }
        }

        &-bottom {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: calc(100% - 90px);
            margin: 45px auto 35px;

            @media only screen and (max-width: 960px) {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: calc(100% - 44px);
                margin: 20px auto 0;
            }

            .footer-linkedin {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                font-family: 'Avenir', sans-serif;
                font-size: 20px;
                line-height: 30px;
                font-weight: bold;
                color: $mkllightgrey;

                @media only screen and (max-width: 960px) {
                    margin-top: -48px;
                    margin-right: 0;
                    margin-left: auto;
                    font-size: 16px;
                    line-height: 24px;
                    order: 2;
                }

                svg {
                    display: block;
                    width: 30px;
                    height: auto;
                    margin-right: 20px;
                    fill: $white;
                }
            }

            .footer-links {
                @media only screen and (max-width: 960px) {
                    display: flex;
                    flex-direction: column;
                }

                a {
                    display: inline-block;
                    margin-right: 24px;
                    font-family: 'Avenir', sans-serif;
                    font-size: 18px;
                    line-height: 22px;
                    font-weight: 400;
                    color: $white;
                    opacity: 0.6;
                    border-bottom: 1px solid transparent;

                    @media only screen and (max-width: 960px) {
                        margin-bottom: 20px;
                        font-size: 16px;
                        line-height: 24px;
                    }

                    &:hover {
                        color: $white;
                        border-bottom: 1px solid $volt;
                        opacity: 1;
                        cursor: pointer;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .makerlab-logo {
        display: block;
        width: 100vw;
        height: auto;
        margin-top: 46px;
        margin-bottom: 36px;
        fill: $white;
        opacity: 0.3;
    }
}