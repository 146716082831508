.makerlab-cases {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0 280px;
    background: $mklprimarygrey;

    @media only screen and (max-width: 960px) {
        padding: 60px 0 100px;
    }

    h3 {
        display: block;
        font-family: 'Avenir', sans-serif;
        font-size: 40px;
        line-height: 50px;
        font-weight: 400;
        color: $black;
        text-align: center;

        @media only screen and (max-width: 960px) {
            font-size: 34px;
            line-height: 40px;
        }
    }

    &-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        margin-top: 70px;

        @media only screen and (max-width: 960px) {
            width: 100%;
            flex-direction: column;
            margin-top: 40px;
        }
    }

    &-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 25vw;
        max-width: 400px;
        min-height: 340px;
        margin-right: 45px;
        padding: 0 55px 80px;
        background: $white;
        text-align: center;
        transition: all 0.3s ease;

        @media only screen and (max-width: 960px) {
            width: 80%;
            min-height: 200px;
            margin-right: 0;
            margin-bottom: 40px;
            padding: 0 25px 40px;
        }

        img {
            display: block;
            max-width: 80%;
            margin: 80px auto 0;
            transition: all 0.3s ease;

            @media only screen and (max-width: 960px) {
                max-width: 60%;
                margin: 40px auto 40px;
            }
        }

        h4 {
            font-family: 'Avenir', sans-serif;
            font-size: 25px;
            line-height: 30px;
            font-weight: 400;
            transition: all 0.3s ease;

            @media only screen and (max-width: 960px) {
                font-size: 20px;
                line-height: 24px;
            }
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            background: $blue;
            cursor: pointer;

            img {
                // mix-blend-mode: luminosity;
                // filter: grayscale(100%) brightness(200%);
                filter: brightness(0) invert(1);
            }

            h4 {
                color: $volt;
            }
        }
    }
}

.makerlab-case {
    &-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: calc(100vw - 88px);
        margin: 100px 44px 0;

        @media only screen and (max-device-width: 960px) {
            flex-direction: column;
            justify-content: flex-start;
            width: calc(100vw - 48px);
            margin: 100px 24px 0;
        }
    }

    &-left {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 40vw;

        position: sticky;
        top: 100px;
        height: fit-content;

        @media only screen and (max-device-width: 960px) {
            position: relative;
            top: 0;
            width: 100%;
        }

        img {
            display: block;
            width: 260px;

            @media only screen and (max-device-width: 960px) {
                width: 140px;
            }
        }

        h2 {
            display: block;
            font-family: 'Avenir', sans-serif;
            font-size: 30px;
            line-height: 36px;
            font-weight: 400;
            color: $blue;
            margin-top: 40px;

            @media only screen and (max-device-width: 960px) {
                font-size: 22px;
                line-height: 30px;
            }
        }
    }

    &-text-wrapper {
        display: block;
        width: 100%;
        margin-top: 20px;
        padding-bottom: 100px;
        white-space: break-spaces;

        @media only screen and (max-device-width: 960px) {
            padding-bottom: 0;
        }

        p {
            display: block;
            font-family: 'Avenir', sans-serif;
            font-size: 20px;
            line-height: 24px;
            font-weight: 400;
            color: $mklprimaryblack;

            @media only screen and (max-device-width: 960px) {
                font-size: 16px;
                line-height: 20px;
            }
        }
        
        b {
            display: block;
            font-weight: 400;
            color: $blue;
        }
    }

    &-right {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 46vw;

        img {
            display: block;
        }

        img:nth-child(3n+1) {
            width: 100%;
            height: auto;
        }

        img:nth-child(3n+2),
        img:nth-child(3n) {
            width: calc(50% - 20px);
            margin-top: 40px;
            margin-bottom: 40px;
        }

        img:nth-child(3n) {
            margin-left: 40px;
        }

        @media only screen and (max-device-width: 960px) {
            width: 100%;
            margin-top: 20px;

            img {
                width: 100% !important;
                margin: 20px 0 0 !important;
            }
        }
    }
}