@import "https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap";
@import "https://fonts.cdnfonts.com/css/avenir";
/*!
 * Bootstrap Reboot v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*, :before, :after {
  box-sizing: border-box;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  width: 100vw;
  font-family: sans-serif;
  line-height: 1.15;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  color: #212529;
  text-align: left;
  background-color: #fff;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

html, body {
  width: 100vw;
}

.home-content {
  width: 100vw;
  overflow-x: hidden;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

abbr[title], abbr[data-original-title] {
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  text-decoration: underline;
  border-bottom: 0;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: inherit;
  background-color: #0000;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

pre {
  -ms-overflow-style: scrollbar;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

th {
  text-align: inherit;
}

label {
  margin-bottom: .5rem;
  display: inline-block;
}

button {
  border-radius: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  width: 100%;
  max-width: 100%;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.makerlab-spinner-container {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100dvh;
  display: flex;
}

.makerlab-scroll-container {
  scroll-snap-type: y mandatory;
  scroll-snap-stop: always;
  height: 100dvh;
  overflow: auto;
}

.makerlab-logo {
  width: 164px;
  height: auto;
  display: block;
}

@media only screen and (width <= 960px) {
  .makerlab-logo--desktop {
    width: 100px;
  }
}

.makerlab-logo--large {
  fill: #fff;
  width: 76vw;
}

.makerlab-section {
  scroll-snap-align: start;
  scroll-snap-stop: always;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100vw;
  height: 100dvh;
  display: flex;
}

.makerlab-section-intro {
  position: relative;
  overflow: hidden;
}

.makerlab-section-intro .makerlab-column {
  width: 100%;
}

.makerlab-section-intro .makerlab-column video {
  object-fit: cover;
  width: 100%;
  height: 100dvh;
  margin-top: 60px;
  display: block;
}

@media only screen and (width <= 960px) {
  .makerlab-section-intro {
    height: 100dvh;
  }
}

.makerlab-section-intro .makerlab-scrolltobottom {
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  margin: 0 auto;
  transition: all .3s;
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  transform: translate3d(0, -20px, 0);
}

.makerlab-section-intro .makerlab-scrolltobottom[data-show="true"] {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.makerlab-section-intro .makerlab-scrolltobottom span {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-family: Avenir, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
}

.makerlab-section-intro .makerlab-scrolltobottom svg {
  width: 60px;
  margin-top: 25px;
  display: block;
}

.makerlab-section-clients {
  position: relative;
}

.makerlab-section-clients .makerlab-column {
  background: #000;
}

.makerlab-section-clients .makerlab-clients-wrapper {
  transition: all .6s .4s;
}

.makerlab-section-clients .clients-text {
  transition: all .6s .2s;
}

.makerlab-section-clients[data-show="false"] .clients-text, .makerlab-section-clients[data-show="false"] .makerlab-clients-wrapper {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
}

.makerlab-section-clients[data-show="true"] .clients-text, .makerlab-section-clients[data-show="true"] .makerlab-clients-wrapper {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

@media only screen and (width <= 960px) {
  .makerlab-section-microagencies h3 {
    width: 84vw !important;
  }
}

.makerlab-section-microagencies .makerlab-column {
  background: #c2c2c2;
}

.makerlab-section-microagencies .clients-text {
  transition: all .6s .2s;
}

.makerlab-section-microagencies[data-show="false"] .clients-text {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
}

.makerlab-section-microagencies[data-show="true"] .clients-text {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.makerlab-section-microagencies .makerlab-carousel-wrapper {
  justify-content: stretch;
  height: 100%;
}

.makerlab-section-microagencies .makerlab-carousel-item {
  align-self: stretch;
}

.makerlab-section-microagencies .makerlab-carousel-item img {
  margin-bottom: -20px;
}

@media only screen and (width <= 960px) {
  .makerlab-section-microagencies .makerlab-carousel-item img {
    margin-bottom: -10px;
  }
}

.makerlab-section-about .makerlab-column {
  background: #000;
}

.makerlab-section-about-wrapper {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 54vw;
  display: flex;
}

@media only screen and (width <= 960px) {
  .makerlab-section-about-wrapper {
    width: 80vw;
    margin-top: -100px;
  }
}

.makerlab-section-about-wrapper h4 {
  font-optical-sizing: auto;
  color: #fff;
  width: 54vw;
  max-width: 570px;
  font-family: Lexend, sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 40px;
  transition: all .6s .2s;
}

@media only screen and (width <= 960px) {
  .makerlab-section-about-wrapper h4 {
    width: 70vw;
    max-width: 320px;
    font-size: 26px;
    line-height: 30px;
  }
}

.makerlab-section-about-wrapper h5 {
  font-optical-sizing: auto;
  color: #fff;
  width: 54vw;
  max-width: 570px;
  margin: 30px 0 72px;
  font-family: Lexend, sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 34px;
  transition: all .6s .4s;
  display: block;
}

@media only screen and (width <= 960px) {
  .makerlab-section-about-wrapper h5 {
    width: 70vw;
    max-width: 320px;
    margin: 30px 0 18px;
    font-size: 20px;
    line-height: 24px;
  }
}

.makerlab-section-about-skills {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

@media only screen and (width <= 960px) {
  .makerlab-section-about-skills {
    flex-direction: column;
    align-items: flex-start;
  }
}

.makerlab-section-about-skills-item {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 15px 0;
  transition: all .6s;
  display: block;
}

.makerlab-section-about-skills-item:not(:last-child) {
  margin-right: 24px;
}

@media only screen and (width <= 960px) {
  .makerlab-section-about-skills-item {
    width: 80%;
    margin-right: 0 !important;
  }
}

.makerlab-section-about-skills-item:first-child {
  transition-delay: .2s;
}

.makerlab-section-about-skills-item:nth-child(2) {
  transition-delay: .4s;
}

.makerlab-section-about-skills-item:nth-child(3) {
  transition-delay: .6s;
}

.makerlab-section-about-skills-item:nth-child(4) {
  transition-delay: .8s;
}

.makerlab-section-about-skills-item:nth-child(5) {
  transition-delay: 1s;
}

.makerlab-section-about-skills-item span {
  font-optical-sizing: auto;
  text-transform: uppercase;
  color: #fff;
  font-family: Lexend, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
}

@media only screen and (width <= 960px) {
  .makerlab-section-about-skills-item span {
    font-size: 22px;
    line-height: 26px;
  }
}

.makerlab-section-about[data-show="false"] h4, .makerlab-section-about[data-show="false"] h5, .makerlab-section-about[data-show="false"] li {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
}

.makerlab-section-about[data-show="true"] h4, .makerlab-section-about[data-show="true"] h5, .makerlab-section-about[data-show="true"] li {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

@media only screen and (width <= 960px) {
  .makerlab-section-champions {
    height: calc(var(--vh, 1vh) * 100 - 70px);
    padding-top: 0;
  }
}

.makerlab-section-champions .makerlab-column {
  background: #000;
  justify-content: flex-start;
  overflow: visible;
}

@media only screen and (width <= 960px) {
  .makerlab-section-champions .makerlab-column {
    height: 100%;
  }
}

.makerlab-section-champions .makerlab-column--right {
  z-index: 5;
  width: 100%;
  position: relative;
}

@media only screen and (width <= 960px) {
  .makerlab-section-champions .makerlab-column--right {
    height: 100%;
    margin-top: 70px;
  }
}

.makerlab-section-champions[data-show="false"] .makerlab-section-champions-top span {
  opacity: 0;
  transition-delay: 0s;
  transform: translate3d(0, 50px, 0);
}

.makerlab-section-champions[data-show="true"] .makerlab-section-champions-top span {
  opacity: 1;
  transition-delay: 0s;
  transform: translate3d(0, 0, 0);
}

.makerlab-section-champions-wrapper {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
}

@media only screen and (width <= 960px) {
  .makerlab-section-champions-wrapper {
    height: 100%;
  }
}

.makerlab-section-champions-top {
  text-align: center;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
}

@media only screen and (width <= 960px) {
  .makerlab-section-champions-top {
    padding: 80px 0 0;
    display: block;
  }
}

.makerlab-section-champions-top span {
  font-optical-sizing: auto;
  color: #fff;
  max-width: 100%;
  font-family: Lexend, sans-serif;
  font-size: 30px;
  font-weight: 300;
  line-height: 40px;
  transition: all .6s .2s;
  display: inline-block;
}

@media only screen and (width <= 960px) {
  .makerlab-section-champions-top span {
    width: 70vw;
    max-width: 320px;
    font-size: 32px;
    line-height: 36px;
  }
}

.makerlab-section-champions-top span.bold-richtext {
  font-weight: 500;
}

.makerlab-section-insights .makerlab-column {
  background: #1851d7;
}

@media only screen and (width <= 960px) {
  .makerlab-section-insights h3 {
    width: 76vw !important;
  }
}

.makerlab-section-insights .clients-text {
  transition: all .6s .2s;
}

@media only screen and (width <= 960px) {
  .makerlab-section-insights .clients-text span {
    font-size: 28px;
    line-height: 32px;
  }
}

.makerlab-section-insights .makerlab-section-insights-number {
  transition: all .6s .4s;
}

.makerlab-section-insights .makerlab-section-insights-text {
  transition: all .6s .5s;
}

.makerlab-section-insights .makerlab-section-insights-wrapper > a {
  transition: all .6s .6s;
}

.makerlab-section-insights[data-show="false"] .clients-text, .makerlab-section-insights[data-show="false"] .makerlab-section-insights-number, .makerlab-section-insights[data-show="false"] .makerlab-section-insights-text, .makerlab-section-insights[data-show="false"] .makerlab-section-insights-wrapper > a {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
}

.makerlab-section-insights[data-show="true"] .clients-text, .makerlab-section-insights[data-show="true"] .makerlab-section-insights-number, .makerlab-section-insights[data-show="true"] .makerlab-section-insights-text, .makerlab-section-insights[data-show="true"] .makerlab-section-insights-wrapper > a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.makerlab-section-insights-wrapper {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 54vw;
  max-width: 570px;
  margin-top: 75px;
  display: flex;
}

@media only screen and (width <= 960px) {
  .makerlab-section-insights-wrapper {
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 76vw;
    margin-top: 35px;
  }
}

.makerlab-section-insights-number {
  font-optical-sizing: auto;
  color: #fff;
  width: 194px;
  font-family: Lexend, sans-serif;
  font-size: 70px;
  font-weight: 500;
  line-height: 54px;
  display: block;
}

@media only screen and (width <= 960px) {
  .makerlab-section-insights-number {
    font-size: 80px;
  }
}

.makerlab-section-insights-text {
  font-optical-sizing: auto;
  color: #fff;
  max-width: 160px;
  margin-left: 24px;
  font-family: Lexend, sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
}

@media only screen and (width <= 960px) {
  .makerlab-section-insights-text {
    max-width: 100%;
    margin-top: 20px;
    margin-left: 4px;
    font-size: 18px;
    line-height: 20px;
  }
}

.makerlab-section-insights-link {
  font-optical-sizing: auto;
  text-align: center;
  text-transform: uppercase;
  color: #000;
  background: #fff;
  border-radius: 27px;
  width: 180px;
  padding: 15px 0;
  font-family: Lexend, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  transition: all .3s;
  display: block;
  transition-delay: 0s !important;
}

@media only screen and (width <= 960px) {
  .makerlab-section-insights-link {
    margin-top: 35px;
  }
}

.makerlab-section-insights-link:hover {
  background: #d6fe00;
}

.makerlab-section-contact .makerlab-column {
  background: #000;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 50px;
}

@media only screen and (width <= 960px) {
  .makerlab-section-contact .makerlab-column {
    padding-bottom: 120px;
  }
}

.makerlab-section-contact-wrapper {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

@media only screen and (width <= 960px) {
  .makerlab-section-contact-wrapper {
    flex-direction: column;
  }

  .makerlab-section-contact-wrapper > div:first-of-type {
    order: 2;
  }
}

.makerlab-section-contact-wrapper-text {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(50% - 50px);
  margin-left: 50px;
  display: flex;
}

@media only screen and (width <= 960px) {
  .makerlab-section-contact-wrapper-text {
    order: 1;
    width: 100%;
  }
}

.makerlab-section-contact-wrapper-text span, .makerlab-section-contact-wrapper-text a {
  font-optical-sizing: auto;
  color: #fff;
  font-family: Lexend, sans-serif;
  font-size: 17px;
  font-weight: 300;
  line-height: 27px;
}

@media only screen and (width <= 960px) {
  .makerlab-section-contact-wrapper-text span, .makerlab-section-contact-wrapper-text a {
    font-size: 14px;
    line-height: 16px;
  }
}

.makerlab-section-contact-wrapper-text span {
  max-width: 50%;
  margin-bottom: 60px;
}

@media only screen and (width <= 960px) {
  .makerlab-section-contact-wrapper-text span {
    max-width: 100%;
    margin-bottom: 15px;
  }
}

.makerlab-section-contact-wrapper-text a {
  color: #afafaf;
  transition: color .3s;
}

.makerlab-section-contact-wrapper-text a:nth-of-type(2) {
  margin-bottom: 10px;
}

.makerlab-section-contact-wrapper-text a:hover {
  color: #fff;
}

.makerlab-column {
  flex-direction: column;
  align-items: center;
  height: 100dvh;
  display: flex;
  overflow: hidden;
}

@media only screen and (width <= 960px) {
  .makerlab-column {
    height: calc(var(--vh, 1vh) * 100);
  }
}

.makerlab-column--left {
  pointer-events: none;
  background: #fff;
  justify-content: space-between;
  width: 20%;
  padding: 50px 0 58px;
  transition: background .6s;
  position: fixed;
  top: 0;
  left: 0;
}

@media only screen and (width <= 960px) {
  .makerlab-column--left {
    z-index: 20;
    background: #fff;
    flex-direction: row;
    width: 100%;
    height: 70px;
    padding: 0 20px;
    position: absolute;
    top: 0;
  }

  .makerlab-column--left .makerlab-logo {
    width: 90px;
  }

  .makerlab-column--left-bottom {
    width: auto !important;
  }

  .makerlab-column--left-bottom svg {
    display: none;
  }
}

.makerlab-column--left-bottom {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 86%;
  display: flex;
}

.makerlab-column--left-bottom svg {
  max-width: 50px;
}

.makerlab-column--left-bottom span + span {
  color: #00000080;
}

.makerlab-column--left h2 {
  opacity: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transition: all .5s;
  display: flex;
  transform: translate3d(0, 0, 0);
}

.makerlab-column--left h2 span {
  font-optical-sizing: auto;
  font-family: Lexend, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

@media only screen and (width <= 960px) {
  .makerlab-column--left h2 span {
    font-size: 12px;
  }
}

.makerlab-column--left .makerlab-logo {
  opacity: 1;
  flex-direction: row;
  max-height: 176px;
  display: flex;
}

.makerlab-column--left .makerlab-bird {
  transition: transform .7s cubic-bezier(.22, 1, .36, 1);
  transform: translate3d(0, 0, 0)scale(1);
}

@media only screen and (width >= 960px) {
  .makerlab-column--left[data-secondarySidebar="true"] {
    background: #d5fd00;
  }

  .makerlab-column--left[data-currentview="insights"], .makerlab-column--left[data-currentview="champions"], .makerlab-column--left[data-secondarySidebar="true"][data-currentview="champions"] {
    transition-duration: 0s;
  }

  .makerlab-column--left[data-secondarySidebar="false"][data-currentview="insights"] {
    background: #fff;
    transition-duration: 0s;
  }

  .makerlab-column--left[data-secondarySidebar="false"][data-currentview="insights"] .makerlab-logo, .makerlab-column--left[data-secondarySidebar="false"][data-currentview="insights"] span {
    display: none;
  }

  .makerlab-column--left[data-secondarySidebar="false"][data-currentview="insights"] .makerlab-bird {
    transform: translate3d(0, -85vh, 0);
  }
}

.makerlab-column--right {
  justify-content: center;
  width: 80%;
}

@media only screen and (width <= 960px) {
  .makerlab-column--right {
    width: 100%;
  }
}

.makerlab-column--right img, .makerlab-column--right video {
  object-fit: cover;
  object-position: center 75%;
  width: 100%;
  height: 100%;
  display: block;
}

.makerlab-column--right h3 {
  width: 54vw;
  max-width: 570px;
  margin: 0 auto;
  display: block;
  position: relative;
}

@media only screen and (width <= 960px) {
  .makerlab-column--right h3 {
    width: 80vw;
    max-width: 400px;
  }
}

.makerlab-column--right h3 span {
  font-optical-sizing: auto;
  color: #fff;
  font-family: Lexend, sans-serif;
  font-size: 30px;
  font-weight: 300;
  line-height: 40px;
}

@media only screen and (width <= 960px) {
  .makerlab-column--right h3 span {
    font-size: 26px;
    line-height: 30px;
  }
}

.makerlab-column--right h3 span.bold-richtext {
  font-weight: 400;
}

.makerlab-clients-wrapper {
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
  width: 54vw;
  max-width: 570px;
  margin: 80px 0 0;
  padding: 0;
  list-style: none;
  display: grid;
}

@media only screen and (width <= 960px) {
  .makerlab-clients-wrapper {
    width: 80vw;
    margin: 40px 0 0;
  }

  .makerlab-clients-wrapper li {
    margin-right: 20px;
  }
}

.makerlab-clients-wrapper li {
  flex: 1 0 calc(25% - 20px);
}

.makerlab-clients-wrapper img {
  object-fit: contain;
  max-width: 100px;
  display: block;
}

.makerlab-carousel-container {
  width: 100%;
  margin-top: 120px;
  display: block;
}

@media only screen and (width <= 960px) {
  .makerlab-carousel-container {
    margin-top: 30px;
  }
}

.makerlab-carousel-wrapper {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  transition: transform .3s;
  display: flex;
  overflow: auto;
}

.makerlab-carousel-wrapper:hover {
  cursor: grab;
}

.makerlab-carousel-wrapper.active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
  transform: scale(1.01);
}

.makerlab-carousel-wrapper::-webkit-scrollbar {
  opacity: 0;
}

.makerlab-carousel-wrapper[data-darkmode="false"] .makerlab-carousel-item {
  background: #fff;
}

.makerlab-carousel-wrapper[data-darkmode="false"] .makerlab-carousel-item-text {
  color: #000;
}

.makerlab-carousel-item {
  background: #343434;
  flex-direction: column;
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  width: 276px;
  height: 100%;
  list-style: none;
  display: flex;
  overflow: hidden;
}

@media only screen and (width <= 960px) {
  .makerlab-carousel-item {
    width: 210px;
    height: 320px;
  }
}

.makerlab-carousel-item:not(:last-child) {
  margin-right: 5px;
}

.makerlab-carousel-item img {
  object-fit: contain;
  justify-self: flex-start;
  align-items: flex-start;
  width: auto;
  height: 100px;
  margin-bottom: 10px;
  display: flex;
}

@media only screen and (width <= 960px) {
  .makerlab-carousel-item img {
    height: 76px;
  }
}

.makerlab-carousel-item-index {
  font-optical-sizing: auto;
  color: #fff;
  margin-top: 24px;
  margin-left: 56px;
  font-family: Lexend, sans-serif;
  font-size: 60px;
  font-weight: 500;
  line-height: 60px;
  display: block;
  position: relative;
}

@media only screen and (width <= 960px) {
  .makerlab-carousel-item-index {
    margin-top: 18px;
    margin-left: 32px;
    font-size: 42px;
  }
}

.makerlab-carousel-item-text {
  font-optical-sizing: auto;
  color: #fff;
  font-family: Lexend, sans-serif;
  font-size: 15px;
  font-weight: 300;
  line-height: 18px;
}

@media only screen and (width <= 960px) {
  .makerlab-carousel-item-text {
    font-size: 14px;
    line-height: 16px;
  }
}

.makerlab-carousel-item--bottom {
  padding: 0 26px 20px;
  display: block;
}

@media only screen and (width <= 960px) {
  .makerlab-carousel-item--bottom {
    padding: 0 16px 20px;
  }
}

.bold-richtext {
  white-space: nowrap;
  font-weight: bolder;
}

::selection {
  color: #000;
  background: #d6fe00;
}

.makerlab-carousel-dots {
  justify-content: flex-start;
  margin-top: 25px;
  margin-left: 28px;
  display: flex;
}

.makerlab-carousel-dot {
  cursor: pointer;
  background-color: #797979;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  transition: background-color .3s;
}

@media screen and (width >= 960px) {
  .makerlab-carousel-dot:hover {
    background-color: #fff;
  }
}

.makerlab-carousel-dot.active {
  background-color: #fff;
}

.makerlab-emailForm {
  flex-direction: column;
  width: 100%;
  display: flex;
}

@media screen and (width <= 960px) {
  .makerlab-emailForm {
    width: 100%;
    margin-top: 15px;
    margin-left: 0;
  }
}

.makerlab-emailForm[data-ty="true"] input, .makerlab-emailForm[data-ty="true"] select, .makerlab-emailForm[data-ty="true"] textarea {
  opacity: .5;
}

.makerlab-emailForm[data-ty="true"] button[type="submit"] {
  color: #fff;
  background: #181917;
  border: 1px solid #181917;
  width: 100%;
}

.makerlab-emailForm-group {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 14px;
  display: flex;
}

.makerlab-emailForm-group input:not(:last-child) {
  margin-right: 14px;
}

@media screen and (width <= 960px) {
  .makerlab-emailForm-group {
    flex-direction: column;
    margin-bottom: 0;
  }
}

.makerlab-emailForm-group .fieldset-region {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
}

.makerlab-emailForm-group .fieldset-region label {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  margin-right: 90px;
  padding-left: 0;
  font-size: 22px;
  display: flex;
  position: relative;
}

.makerlab-emailForm-group .fieldset-region label input {
  opacity: 0;
  cursor: pointer;
  position: absolute;
}

.makerlab-emailForm-group .fieldset-region label input:checked ~ .checkmark {
  background-color: #000;
}

.makerlab-emailForm-group .fieldset-region label .checkmark {
  will-change: background-color;
  background-color: #0000;
  border: 1px solid #000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  transition: background-color .3s;
  display: block;
  position: relative;
}

@media screen and (width <= 960px) {
  .makerlab-emailForm-group .fieldset-region {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
    margin-right: 0;
  }

  .makerlab-emailForm-group .fieldset-region label {
    margin-bottom: 10px;
  }
}

.makerlab-emailForm h4 {
  color: #181917;
  margin-bottom: 30px;
  font-family: Avenir, sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 34px;
}

@media screen and (width <= 960px) {
  .makerlab-emailForm h4 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
  }
}

.makerlab-emailForm input:not([type="radio"]), .makerlab-emailForm textarea {
  font-optical-sizing: auto;
  color: #00000080;
  box-shadow: none;
  background: none;
  border: 1px solid #00000080;
  border-radius: 120px;
  outline: none;
  width: 100%;
  margin: 40px 0;
  padding: 12px 20px;
  font-family: Avenir, sans-serif;
  font-size: 20px;
  font-weight: 400;
  display: block;
}

.makerlab-emailForm input:not([type="radio"])::placeholder, .makerlab-emailForm textarea::placeholder {
  color: #000c;
}

@media screen and (width <= 960px) {
  .makerlab-emailForm input:not([type="radio"]), .makerlab-emailForm textarea {
    color: #000c;
    border: 1px solid #000c;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 6px;
    padding: 6px 20px 6px 25px;
    font-size: 14px;
  }
}

.makerlab-emailForm textarea {
  resize: none;
  margin: 0 0 40px;
}

.makerlab-emailForm button {
  font-optical-sizing: auto;
  color: #000;
  background: none;
  border: 1px solid #000;
  border-radius: 62px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 40px;
  font-family: Avenir, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 38px;
  transition: all .3s;
  display: flex;
}

@media screen and (width <= 960px) {
  .makerlab-emailForm button {
    margin-top: 16px;
    padding: 12px 36px;
    font-size: 14px;
  }
}

.makerlab-emailForm button:hover {
  color: #fff;
  background: #1851d7;
  border-color: #fff;
}

header {
  z-index: 101;
  background-color: #fff;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 60px;
  padding: 0 44px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

@media only screen and (device-width <= 960px) {
  header {
    padding: 0 24px;
  }
}

header ul {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

header ul li {
  color: #181917;
  font-size: 14px;
  font-weight: 400;
  display: block;
}

header ul li:not(:last-child) {
  margin-right: 29px;
}

@media only screen and (device-width <= 960px) {
  header ul li:not(:last-child) {
    display: none;
  }
}

header ul li:last-child {
  color: #181917;
  background: #d5fd00;
  border: 1px solid #181917;
  border-radius: 18px;
  padding: 0 9px;
  transition: all .3s;
}

header ul li:last-child:hover {
  color: #fff;
  background: #1851d7;
  border-color: #fff;
}

header ul li:hover {
  cursor: pointer;
}

header ul li:hover:not(:last-child) span, header ul li:hover:not(:last-child) a {
  border-bottom: 1px solid #1851d7;
}

.makerlab-section-fading-screen {
  z-index: 100;
  background-color: #181917;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100dvh;
  display: flex;
  position: relative;
}

.makerlab-section-fading-screen .makerlab-fading-screen {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.makerlab-section-fading-screen .makerlab-fading-screen svg {
  width: 160px;
  display: block;
}

@media only screen and (width <= 960px) {
  .makerlab-section-fading-screen .makerlab-fading-screen svg {
    width: 120px;
  }
}

.makerlab-section-fading-screen .makerlab-fading-screen h2 {
  color: #fff;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 100px;
  font-family: Avenir, sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 50px;
  display: flex;
}

.makerlab-section-fading-screen .makerlab-fading-screen h2 p {
  margin-right: 10px;
}

@media only screen and (width <= 960px) {
  .makerlab-section-fading-screen .makerlab-fading-screen h2 p {
    margin-right: 0;
  }
}

.makerlab-section-fading-screen .makerlab-fading-screen h2 div {
  color: #d5fd00;
  display: block;
  position: relative;
  overflow: hidden;
}

.makerlab-section-fading-screen .makerlab-fading-screen h2 span:nth-child(2) {
  color: #d5fd00;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: 400;
  line-height: 50px;
  display: flex;
}

@media only screen and (width <= 960px) {
  .makerlab-section-fading-screen .makerlab-fading-screen h2 {
    flex-direction: column;
    font-size: 30px;
    line-height: 50px;
  }

  .makerlab-section-fading-screen .makerlab-fading-screen h2 span:nth-child(2) {
    font-size: 20px;
    line-height: 40px;
  }
}

.makerlab-logos-wrapper > div {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 170px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

@media only screen and (width <= 960px) {
  .makerlab-logos-wrapper > div {
    height: 100px;
  }
}

.makerlab-logos-wrapper .makerlab-logos-item {
  margin-right: 90px;
}

.makerlab-logos-wrapper img {
  mix-blend-mode: difference;
  width: 170px;
}

@media only screen and (width <= 960px) {
  .makerlab-logos-wrapper img {
    width: 80px;
  }
}

.makerlab-talent-wrapper {
  background-color: #d5fd00;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  height: 100dvh;
  padding: 126px 20px;
  display: flex;
  position: relative;
}

@media only screen and (width <= 960px) {
  .makerlab-talent-wrapper {
    height: calc(var(--vh, 1vh) * 100);
    justify-content: center;
    padding: 60px 20px;
  }
}

.makerlab-talent-wrapper h3 {
  color: #000;
  margin-bottom: 110px;
  font-family: Avenir, sans-serif;
  font-size: 60px;
  font-weight: 400;
  line-height: 50px;
  display: block;
}

@media only screen and (width <= 960px) {
  .makerlab-talent-wrapper h3 {
    margin-bottom: 60px;
    font-size: 34px;
    line-height: 34px;
  }
}

.makerlab-talent-wrapper ul {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 200px);
  margin: 0 auto;
  list-style: none;
  display: flex;
}

@media only screen and (width <= 960px) {
  .makerlab-talent-wrapper ul {
    width: 100%;
    padding: 0;
  }
}

.makerlab-talent-wrapper ul li {
  color: #00000080;
  text-align: center;
  border-top: 1px solid #00000080;
  width: 100%;
  font-family: Avenir, sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 82px;
  transition: all .5s;
  display: block;
}

@media only screen and (width <= 960px) {
  .makerlab-talent-wrapper ul li {
    font-size: 20px;
    line-height: 54px;
  }
}

.makerlab-talent-wrapper ul li:last-child {
  border-bottom: 1px solid #00000080;
}

.makerlab-talent-wrapper ul li.highlight {
  color: #181917;
  padding: 3px 0;
  font-size: 60px;
}

@media only screen and (width <= 960px) {
  .makerlab-talent-wrapper ul li.highlight {
    font-size: 34px;
  }
}

.makerlab-talent-wrapper ul li:hover {
  cursor: default;
}

.makerlab-talent-wrapper h4 {
  color: #181917;
  text-align: center;
  width: 54vw;
  max-width: 580px;
  margin-top: 40px;
  font-family: Avenir, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  display: block;
}

@media only screen and (width <= 960px) {
  .makerlab-talent-wrapper h4 {
    width: 100%;
    margin-top: 60px;
    font-size: 18px;
  }
}

.makerlab-talent-wrapper .makerlab-scrolltobottom {
  width: 40px;
  margin-top: 50px;
  margin-bottom: 0;
  display: block;
  position: absolute;
  bottom: 40px;
}

.makerlab-map-wrapper {
  background-color: #1851d7;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  display: flex;
  position: relative;
}

.makerlab-map-wrapper h3 {
  color: #fff;
  margin-top: 54px;
  font-family: Avenir, sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 80px;
}

@media only screen and (width <= 960px) {
  .makerlab-map-wrapper h3 {
    font-size: 34px;
    line-height: 40px;
  }
}

.makerlab-map-wrapper .makerlab-map-svg {
  width: 72%;
  margin-top: 30px;
  display: block;
}

@media only screen and (width <= 960px) {
  .makerlab-map-wrapper .makerlab-map-svg {
    width: 90%;
    margin-top: 0;
  }
}

.makerlab-map-wrapper .makerlab-map-svg svg {
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-height: 50vh;
  display: block;
}

.makerlab-map-wrapper .makerlab-map-svg svg g {
  transition: opacity .5s;
}

.makerlab-map-wrapper .makerlab-map-svg svg #m-g-map {
  opacity: .8;
}

.makerlab-map-wrapper .makerlab-map-svg svg .g-group {
  will-change: opacity;
}

.makerlab-map-wrapper .makerlab-map-svg[data-active="true"] svg #m-g-map {
  opacity: .6;
}

.makerlab-map-wrapper .makerlab-map-texts {
  text-align: center;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 40px;
  padding-bottom: 30px;
  display: flex;
}

@media only screen and (width <= 960px) {
  .makerlab-map-wrapper .makerlab-map-texts {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 0;
  }
}

.makerlab-map-wrapper .makerlab-map-texts[data-show="true"] .makerlab-map-text {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.makerlab-map-wrapper .makerlab-map-texts[data-show="true"] .makerlab-map-text:nth-child(0) {
  transition-delay: 0;
}

.makerlab-map-wrapper .makerlab-map-texts[data-show="true"] .makerlab-map-text:first-child {
  transition-delay: .2s;
}

.makerlab-map-wrapper .makerlab-map-texts[data-show="true"] .makerlab-map-text:nth-child(2) {
  transition-delay: .3s;
}

.makerlab-map-wrapper .makerlab-map-texts .makerlab-map-text {
  opacity: 0;
  will-change: opacity, transform;
  max-width: 330px;
  transition: all .5s .5s;
  display: block;
  transform: translate3d(0, 20px, 0);
}

.makerlab-map-wrapper .makerlab-map-texts h4, .makerlab-map-wrapper .makerlab-map-texts p {
  font-family: Avenir, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

@media only screen and (width <= 960px) {
  .makerlab-map-wrapper .makerlab-map-texts h4, .makerlab-map-wrapper .makerlab-map-texts p {
    font-size: 16px;
    line-height: 20px;
  }
}

.makerlab-map-wrapper .makerlab-map-texts h4 {
  color: #d5fd00;
}

.makerlab-map-wrapper .makerlab-map-texts p {
  color: #fff;
}

@media only screen and (width <= 960px) {
  .makerlab-map-wrapper .makerlab-map-texts p {
    margin-bottom: 30px;
  }
}

.makerlab-cases {
  background: #d9d9d9;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0 280px;
  display: flex;
}

@media only screen and (width <= 960px) {
  .makerlab-cases {
    padding: 60px 0 100px;
  }
}

.makerlab-cases h3 {
  color: #000;
  text-align: center;
  font-family: Avenir, sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 50px;
  display: block;
}

@media only screen and (width <= 960px) {
  .makerlab-cases h3 {
    font-size: 34px;
    line-height: 40px;
  }
}

.makerlab-cases-wrapper {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  display: flex;
}

@media only screen and (width <= 960px) {
  .makerlab-cases-wrapper {
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
  }
}

.makerlab-cases-item {
  text-align: center;
  background: #fff;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 25vw;
  max-width: 400px;
  min-height: 340px;
  margin-right: 45px;
  padding: 0 55px 80px;
  transition: all .3s;
  display: flex;
}

@media only screen and (width <= 960px) {
  .makerlab-cases-item {
    width: 80%;
    min-height: 200px;
    margin-bottom: 40px;
    margin-right: 0;
    padding: 0 25px 40px;
  }
}

.makerlab-cases-item img {
  max-width: 80%;
  margin: 80px auto 0;
  transition: all .3s;
  display: block;
}

@media only screen and (width <= 960px) {
  .makerlab-cases-item img {
    max-width: 60%;
    margin: 40px auto;
  }
}

.makerlab-cases-item h4 {
  font-family: Avenir, sans-serif;
  font-size: 25px;
  font-weight: 400;
  line-height: 30px;
  transition: all .3s;
}

@media only screen and (width <= 960px) {
  .makerlab-cases-item h4 {
    font-size: 20px;
    line-height: 24px;
  }
}

.makerlab-cases-item:last-child {
  margin-right: 0;
}

.makerlab-cases-item:hover {
  cursor: pointer;
  background: #1851d7;
}

.makerlab-cases-item:hover img {
  filter: brightness(0) invert();
}

.makerlab-cases-item:hover h4 {
  color: #d5fd00;
}

.makerlab-case-wrapper {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100vw - 88px);
  margin: 100px 44px 0;
  display: flex;
}

@media only screen and (device-width <= 960px) {
  .makerlab-case-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    width: calc(100vw - 48px);
    margin: 100px 24px 0;
  }
}

.makerlab-case-left {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 40vw;
  height: fit-content;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
}

@media only screen and (device-width <= 960px) {
  .makerlab-case-left {
    width: 100%;
    position: relative;
    top: 0;
  }
}

.makerlab-case-left img {
  width: 260px;
  display: block;
}

@media only screen and (device-width <= 960px) {
  .makerlab-case-left img {
    width: 140px;
  }
}

.makerlab-case-left h2 {
  color: #1851d7;
  margin-top: 40px;
  font-family: Avenir, sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  display: block;
}

@media only screen and (device-width <= 960px) {
  .makerlab-case-left h2 {
    font-size: 22px;
    line-height: 30px;
  }
}

.makerlab-case-text-wrapper {
  white-space: break-spaces;
  width: 100%;
  margin-top: 20px;
  padding-bottom: 100px;
  display: block;
}

@media only screen and (device-width <= 960px) {
  .makerlab-case-text-wrapper {
    padding-bottom: 0;
  }
}

.makerlab-case-text-wrapper p {
  color: #181917;
  font-family: Avenir, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  display: block;
}

@media only screen and (device-width <= 960px) {
  .makerlab-case-text-wrapper p {
    font-size: 16px;
    line-height: 20px;
  }
}

.makerlab-case-text-wrapper b {
  color: #1851d7;
  font-weight: 400;
  display: block;
}

.makerlab-case-right {
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 46vw;
  display: flex;
}

.makerlab-case-right img {
  display: block;
}

.makerlab-case-right img:nth-child(3n+1) {
  width: 100%;
  height: auto;
}

.makerlab-case-right img:nth-child(3n+2), .makerlab-case-right img:nth-child(3n) {
  width: calc(50% - 20px);
  margin-top: 40px;
  margin-bottom: 40px;
}

.makerlab-case-right img:nth-child(3n) {
  margin-left: 40px;
}

@media only screen and (device-width <= 960px) {
  .makerlab-case-right {
    width: 100%;
    margin-top: 20px;
  }

  .makerlab-case-right img {
    width: 100% !important;
    margin: 20px 0 0 !important;
  }
}

.makerlab-section-champions-wrapper {
  white-space: nowrap;
  height: 100dvh;
  display: block;
  overflow: hidden;
}

@media only screen and (width <= 960px) {
  .makerlab-section-champions-wrapper {
    height: calc(var(--vh, 1vh) * 100);
  }
}

.makerlab-carousel-container {
  z-index: 10;
  width: 100vw;
  margin-top: 0;
  display: inline-block !important;
}

@media only screen and (width <= 960px) {
  .makerlab-carousel-container {
    margin-top: 0;
  }
}

.makerlab-carousel-container .makerlab-carousel-wrapper {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100dvh;
  margin: 0;
  padding: 0;
  display: flex;
  overflow: visible;
  transition: none !important;
}

@media only screen and (width <= 960px) {
  .makerlab-carousel-container .makerlab-carousel-wrapper {
    height: calc(var(--vh, 1vh) * 100);
  }
}

.makerlab-carousel-container .makerlab-carousel-wrapper:hover {
  cursor: default;
}

.makerlab-carousel-container .makerlab-carousel-wrapper::-webkit-scrollbar {
  opacity: 0;
}

.makerlab-carousel-container .makerlab-carousel-item {
  background: none;
  width: 33vw;
  min-width: 470px;
  height: 100dvh;
  max-height: 100%;
  margin: 0;
  padding: 200px 0 0;
  position: relative;
}

@media only screen and (width <= 960px) {
  .makerlab-carousel-container .makerlab-carousel-item {
    width: 100vw;
    min-width: 100vw;
    height: 100dvh;
    padding: 0;
  }
}

.makerlab-carousel-container .makerlab-carousel-item:hover .carousel-overlay {
  opacity: 0;
}

.makerlab-carousel-container .makerlab-carousel-item .carousel-overlay {
  opacity: 1;
  z-index: 1;
  pointer-events: none;
  background: #181917b3;
  width: 100%;
  height: 100%;
  transition: opacity .5s;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (width <= 960px) {
  .makerlab-carousel-container .makerlab-carousel-item .carousel-overlay {
    display: none;
  }
}

.makerlab-carousel-container .makerlab-carousel-item--bottom {
  width: 100%;
  height: 100%;
  padding: 0;
}

.makerlab-carousel-container .makerlab-carousel-item--bottom img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

@media only screen and (width <= 960px) {
  .makerlab-carousel-container .makerlab-carousel-item--bottom img {
    max-height: 100%;
  }
}

.makerlab-carousel-container .makerlab-carousel-item--bottom .makerlab-carousel-item-text-column {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 30px;
  display: flex;
  position: absolute;
  top: 174px;
  left: 50px;
}

@media only screen and (width <= 960px) {
  .makerlab-carousel-container .makerlab-carousel-item--bottom .makerlab-carousel-item-text-column {
    top: 70px;
    left: 0;
  }
}

.makerlab-carousel-container .makerlab-carousel-item--bottom .makerlab-carousel-item-text-column[data-dark="true"] .makerlab-carousel-item-text {
  color: #000;
}

.makerlab-carousel-container .makerlab-carousel-item--bottom .makerlab-carousel-item-text-column a svg {
  width: 32px;
  margin-top: 16px;
  display: block;
}

.makerlab-carousel-container .makerlab-carousel-item--bottom .makerlab-carousel-item-text {
  color: #fff;
  font-family: Avenir, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.makerlab-carousel-container .makerlab-carousel-item--bottom .makerlab-carousel-item-text--bold {
  margin-right: 15px;
  font-size: 30px;
  line-height: 38px;
}

.makerlab-contact-wrapper {
  background-color: #d5fd00;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 720px;
  max-height: 100dvh;
  display: flex;
}

@media only screen and (width <= 960px) {
  .makerlab-contact-wrapper {
    height: 100dvh;
  }
}

.makerlab-contact-wrapper .makerlab-contact-content {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  padding: 0;
  display: flex;
}

@media only screen and (width <= 960px) {
  .makerlab-contact-wrapper .makerlab-contact-content {
    max-width: 90%;
  }
}

.makerlab-contact-wrapper h3 {
  color: #181917;
  font-family: Avenir, sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 24px;
}

@media screen and (width <= 960px) {
  .makerlab-contact-wrapper h3 {
    font-size: 34px;
    line-height: 40px;
  }
}

footer {
  background-color: #000;
  flex-direction: column;
  width: 100vw;
  min-height: 600px;
  display: flex;
}

@media only screen and (width <= 960px) {
  footer {
    min-height: auto;
    padding-bottom: 40px;
  }
}

footer .makerlab-footer-top {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 90px);
  margin: 52px auto 0;
  display: flex;
}

@media only screen and (width <= 960px) {
  footer .makerlab-footer-top {
    flex-direction: column;
    width: 100%;
    margin: 24px auto 0;
    padding: 0 22px;
  }
}

footer .makerlab-footer-top .makerlab-miroma {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 60%;
  margin-top: 48px;
  display: flex;
}

@media only screen and (width <= 960px) {
  footer .makerlab-footer-top .makerlab-miroma {
    width: 100%;
    margin-top: 0;
  }
}

footer .makerlab-footer-top .makerlab-miroma span {
  color: #fff;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-family: Avenir, sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  display: flex;
}

footer .makerlab-footer-top .makerlab-miroma span svg {
  width: 330px;
  margin-left: 12px;
  display: block;
}

@media only screen and (width <= 960px) {
  footer .makerlab-footer-top .makerlab-miroma span {
    font-size: 20px;
    line-height: 40px;
  }

  footer .makerlab-footer-top .makerlab-miroma span svg {
    width: 150px;
  }
}

footer .makerlab-footer-top .makerlab-miroma small {
  color: #fff;
  width: 100%;
  font-family: Avenir, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 48px;
  display: block;
}

@media only screen and (width <= 960px) {
  footer .makerlab-footer-top .makerlab-miroma small {
    font-size: 12px;
    line-height: 16px;
  }
}

footer .makerlab-footer-nav {
  margin: auto 0 0 auto;
  display: block;
}

@media only screen and (width <= 960px) {
  footer .makerlab-footer-nav {
    margin: 40px 0 0;
  }
}

footer .makerlab-footer-nav ul {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

footer .makerlab-footer-nav ul li {
  margin: 0;
  padding: 0;
}

footer .makerlab-footer-nav ul li:hover span, footer .makerlab-footer-nav ul li:hover a {
  color: #fff;
  cursor: pointer;
  border-bottom: 1px solid #d5fd00;
}

footer .makerlab-footer-nav ul li:last-child a {
  margin-bottom: 0;
}

footer .makerlab-footer-nav ul li span, footer .makerlab-footer-nav ul li a {
  color: #ffffff80;
  border-bottom: 1px solid #0000;
  margin: 10px 0;
  font-family: Avenir, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  display: block;
}

@media only screen and (width <= 960px) {
  footer .makerlab-footer-nav ul li span, footer .makerlab-footer-nav ul li a {
    margin: 8px 0;
    font-size: 16px;
    line-height: 20px;
  }
}

footer .makerlab-footer-backtotop {
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 0 0 auto;
  font-family: Avenir, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

footer .makerlab-footer-backtotop svg {
  width: 30px;
  margin-bottom: 24px;
  display: block;
}

@media only screen and (width <= 960px) {
  footer .makerlab-footer-backtotop {
    margin: -56px 0 0 auto;
    font-size: 16px;
    line-height: 20px;
  }

  footer .makerlab-footer-backtotop svg {
    width: 20px;
    margin-bottom: 16px;
  }
}

footer .makerlab-footer-backtotop:hover {
  cursor: pointer;
}

footer .makerlab-footer-bottom {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 90px);
  margin: 45px auto 35px;
  display: flex;
}

@media only screen and (width <= 960px) {
  footer .makerlab-footer-bottom {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 44px);
    margin: 20px auto 0;
  }
}

footer .makerlab-footer-bottom .footer-linkedin {
  color: #fbfbfd;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: Avenir, sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  display: flex;
}

@media only screen and (width <= 960px) {
  footer .makerlab-footer-bottom .footer-linkedin {
    order: 2;
    margin-top: -48px;
    margin-left: auto;
    margin-right: 0;
    font-size: 16px;
    line-height: 24px;
  }
}

footer .makerlab-footer-bottom .footer-linkedin svg {
  fill: #fff;
  width: 30px;
  height: auto;
  margin-right: 20px;
  display: block;
}

@media only screen and (width <= 960px) {
  footer .makerlab-footer-bottom .footer-links {
    flex-direction: column;
    display: flex;
  }
}

footer .makerlab-footer-bottom .footer-links a {
  color: #fff;
  opacity: .6;
  border-bottom: 1px solid #0000;
  margin-right: 24px;
  font-family: Avenir, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  display: inline-block;
}

@media only screen and (width <= 960px) {
  footer .makerlab-footer-bottom .footer-links a {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 24px;
  }
}

footer .makerlab-footer-bottom .footer-links a:hover {
  color: #fff;
  opacity: 1;
  cursor: pointer;
  border-bottom: 1px solid #d5fd00;
}

footer .makerlab-footer-bottom .footer-links a:last-child {
  margin-right: 0;
}

footer .makerlab-logo {
  fill: #fff;
  opacity: .3;
  width: 100vw;
  height: auto;
  margin-top: 46px;
  margin-bottom: 36px;
  display: block;
}

.makerlab-cookie-wrapper {
  z-index: 900;
  opacity: 0;
  pointer-events: none;
  background: #000c;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100dvh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.makerlab-cookie-wrapper[data-show="true"] {
  opacity: 1;
  pointer-events: all;
}

.makerlab-cookie-box {
  background: #d5fd00;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 70%;
  max-width: 960px;
  height: 60%;
  max-height: 620px;
  display: flex;
  overflow: hidden;
}

@media only screen and (width <= 960px) {
  .makerlab-cookie-box {
    width: 90%;
    height: auto;
    padding: 40px 0;
  }
}

.makerlab-cookie-box .makerlab-cookie-logo {
  pointer-events: none;
  opacity: .1;
  height: 100%;
  display: block;
}

@media only screen and (width <= 960px) {
  .makerlab-cookie-box .makerlab-cookie-logo {
    display: none;
  }
}

.makerlab-cookie-box .makerlab-cookie-close {
  width: 23px;
  height: 23px;
  margin: 50px 50px 0 -50px;
  display: block;
}

.makerlab-cookie-box .makerlab-cookie-close:hover {
  cursor: pointer;
}

.makerlab-cookie-buttons {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 40px 60px 0 0;
  display: flex;
}

.makerlab-cookie-buttons button {
  color: #181917;
  background: none;
  border: 1px solid #181917;
  border-radius: 100px;
  outline: none;
  width: 100px;
  height: 33px;
  margin: 0 20px 0 0;
  font-family: Avenir, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  transition: all .3s;
  display: block;
}

.makerlab-cookie-buttons button:hover {
  cursor: pointer;
  color: #fff;
  background: #1851d7;
  border-color: #1851d7;
}

.makerlab-cookie-text {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  height: 100%;
  margin: 0 0 0 60px;
  display: flex;
}

@media only screen and (width <= 960px) {
  .makerlab-cookie-text {
    width: calc(100% - 44px);
    margin: 0 auto;
  }
}

.makerlab-cookie-text h4 {
  color: #1851d7;
  margin-bottom: 30px;
  font-family: Avenir, sans-serif;
  font-size: 24px;
  font-weight: 400;
  display: block;
}

.makerlab-cookie-text p {
  color: #181917;
  font-family: Avenir, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  display: block;
}

.makerlab-cookie-text p a {
  border-bottom: 1px solid #1851d7;
}

.makerlab-numbers-wrapper {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100dvh;
  display: flex;
}

@media only screen and (width <= 960px) {
  .makerlab-numbers-wrapper {
    height: calc(var(--vh, 1vh) * 100);
  }
}

.makerlab-numbers-scroll {
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: auto;
  height: 100dvh;
  display: flex;
}

.makerlab-numbers-screen {
  background: #d9d9d9;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  display: flex;
}

.makerlab-numbers-screen h4, .makerlab-numbers-screen h5, .makerlab-numbers-screen h6 {
  font-family: Avenir, sans-serif;
  font-weight: 400;
}

.makerlab-numbers-screen h4 {
  color: #000;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  font-size: 70px;
  line-height: 84px;
  display: flex;
}

@media only screen and (width <= 960px) {
  .makerlab-numbers-screen h4 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    line-height: 50px;
  }
}

.makerlab-numbers-screen h4 p {
  width: 100%;
}

.makerlab-numbers-screen h4 svg {
  opacity: 0;
  width: 30px;
  margin-left: auto;
  margin-right: 100px;
  animation: 1s ease-out .8s forwards slideIn;
  display: block;
}

@media only screen and (width <= 960px) {
  .makerlab-numbers-screen h4 svg {
    display: none;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.makerlab-numbers-screen h5 {
  font-size: 150px;
  line-height: 150px;
}

@media only screen and (width <= 960px) {
  .makerlab-numbers-screen h5 {
    font-size: 90px;
    line-height: 90px;
  }
}

.makerlab-numbers-screen h5.blue {
  color: #1851d7;
}

.makerlab-numbers-screen h5 small {
  font-size: 60px;
  line-height: 50px;
}

@media only screen and (width <= 960px) {
  .makerlab-numbers-screen h5 small {
    font-size: 40px;
    line-height: 40px;
  }
}

.makerlab-numbers-screen h6 {
  color: #000;
  font-size: 25px;
  line-height: 30px;
}

@media only screen and (width <= 960px) {
  .makerlab-numbers-screen h6 {
    text-align: center;
    width: 80%;
    font-size: 20px;
    line-height: 25px;
  }
}

.makerlab-numbers-screen--blue {
  background: #1851d7;
}

.makerlab-numbers-screen--blue h5 {
  color: #d5fd00;
}

.makerlab-numbers-screen--blue h6 {
  color: #fff;
}

.makerlab-numbers-screen--volt {
  background: #d5fd00;
}

.makerlab-numbers-screen--volt h5 {
  color: #1851d7;
}

.makerlab-numbers-screen--volt h6 {
  color: #000;
}

.roboto-thin {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 100;
}

.roboto-regular {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
}
/*# sourceMappingURL=index.c680560b.css.map */
