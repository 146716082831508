@import 'intro';
@import 'generals';
@import 'form';
@import 'header';
@import 'fadingscreen';
@import 'logos';
@import 'talent';
@import 'map';
@import 'cases';
@import 'champions';
@import 'contact';
@import 'footer';
@import 'cookie';
@import 'numbers';

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');
@import url('https://fonts.cdnfonts.com/css/avenir');

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}