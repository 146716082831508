.makerlab-section-fading-screen {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100dvh;
    background-color: $mklprimaryblack;
    z-index: 100;
    //transition: all $introAnimationDuration ease;

    .makerlab-fading-screen {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        svg {
            display: block;
            width: 160px;

            @media only screen and (max-width: 960px) {
                width: 120px;
            }
        }

        h2 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 100px;
            font-family: 'Avenir', sans-serif;
            font-size: 40px;
            line-height: 50px;
            font-weight: 400;
            color: $white;

            p {
                margin-right: 10px;

                @media only screen and (max-width: 960px) {
                    margin-right: 0;
                }
            }

            div {
                position: relative;
                display: block;
                overflow: hidden;
                color: $volt;
            }

            span:nth-child(2) {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-size: 40px;
                line-height: 50px;
                font-weight: 400;
                color: $volt;
            }

            @media only screen and (max-width: 960px) {
                flex-direction: column;
                font-size: 30px;
                line-height: 50px;

                span:nth-child(2) {
                    font-size: 20px;
                    line-height: 40px;
                }
            }
        }
    }
}