.makerlab-cookie {
  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100dvh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 900;
    opacity: 0;
    pointer-events: none;

    &[data-show="true"] {
      opacity: 1;
      pointer-events: all;
    }
  }

  &-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 70%;
    max-width: 960px;
    height: 60%;
    max-height: 620px;
    background: $volt;
    overflow: hidden;

    @media only screen and (max-width: 960px) {
      width: 90%;
      height: auto;
      padding: 40px 0;
    }

    .makerlab-cookie-logo {
      display: block;
      height: 100%;
      pointer-events: none;
      opacity: 0.1;

      @media only screen and (max-width: 960px) {
        display: none;
      }
    }

    .makerlab-cookie-close {
      display: block;
      width: 23px;
      height: 23px;
      margin: 50px 50px 0 -50px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 40px 60px 0 0;

    button {
        display: block;
        width: 100px;
        height: 33px;
        margin: 0 20px 0 0;
        background: transparent;
        color: $mklprimaryblack;
        font-family: "Avenir", sans-serif;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        border: 1px solid $mklprimaryblack;
        border-radius: 100px;
        outline: none;
        transition: all 0.3s ease;

        &:hover {
          cursor: pointer;
          background: $blue;
          border-color: $blue;
          color: $white;
        }
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
    height: 100%;
    margin: 0 0 0 60px;

    @media only screen and (max-width: 960px) {
      width: calc(100% - 44px);
      margin: 0 auto;
    }

    h4 {
        display: block;
        margin-bottom: 30px;
        font-family: "Avenir", sans-serif;
        font-size: 24px;
        font-weight: 400;
        color: $blue;
    }

    p {
        display: block;
        font-family: "Avenir", sans-serif;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: $mklprimaryblack;

        a {
          border-bottom: 1px solid $blue;
        }
    }
  }
}