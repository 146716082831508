.makerlab-talent-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
    height: 100dvh;
    padding: 126px 20px;
    background-color: $volt;

    @media only screen and (max-width: 960px) {
        height: calc(var(--vh, 1vh) * 100);
    }

    @media only screen and (max-width: 960px) {
        justify-content: center;
        padding: 60px 20px;
    }

    h3 {
        display: block;
        margin-bottom: 110px;
        font-family: 'Avenir', sans-serif;
        font-size: 60px;
        line-height: 50px;
        font-weight: 400;
        color: #000000;

        @media only screen and (max-width: 960px) {
            margin-bottom: 60px;
            font-size: 34px;
            line-height: 34px;
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        list-style: none;
        width: calc(100vw - 200px);
        margin: 0 auto;

        @media only screen and (max-width: 960px) {
            width: 100%;
            padding: 0;
        }

        li {
            display: block;
            width: 100%;
            font-family: 'Avenir', sans-serif;
            font-size: 30px;
            line-height: 82px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            text-align: center;
            border-top: 1px solid rgba(0, 0, 0, 0.5);
            transition: all 0.5s ease;

            @media only screen and (max-width: 960px) {
                font-size: 20px;
                line-height: 54px;
            }

            &:last-child {
                border-bottom: 1px solid rgba(0, 0, 0, 0.5);
            }

            &.highlight {
                padding: 3px 0;
                color: $mklprimaryblack;
                font-size: 60px;

                @media only screen and (max-width: 960px) {
                    font-size: 34px;
                }
            }

            &:hover {
                cursor: default;
            }
        }
    }

    h4 {
        display: block;
        width: 54vw;
        max-width: 580px;
        margin-top: 40px;
        font-family: 'Avenir', sans-serif;
        font-size: 20px;
        line-height: 25px;
        font-weight: 400;
        color: $mklprimaryblack;
        text-align: center;

        @media only screen and (max-width: 960px) {
            width: 100%;
            margin-top: 60px;
            font-size: 18px;
        }
    }

    .makerlab-scrolltobottom {
        position: absolute;
        bottom: 40px;
        display: block;
        width: 40px;
        margin-top: 50px;
        margin-bottom: 0;
    }
}