.makerlab-numbers {
  &-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100dvh;

    @media only screen and (max-width: 960px) {
      height: calc(var(--vh, 1vh) * 100);
    }
  }

  &-scroll {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: auto;
    height: 100dvh;
  }

  &-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    background: $mklprimarygrey;

    h4,
    h5,
    h6 {
      font-family: "Avenir", sans-serif;
      font-weight: 400;
    }

    h4 {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      font-size: 70px;
      line-height: 84px;
      color: $black;
      text-align: center;

      @media only screen and (max-width: 960px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        line-height: 50px;
      }

      p {
        width: 100%;
      }

      svg {
        display: block;
        width: 30px;
        margin-left: auto;
        margin-right: 100px;
        opacity: 0;
        animation: slideIn 1s ease-out 0.8s forwards;

        @media only screen and (max-width: 960px) {
          display: none;
          //width: 16px;
          //margin: 30px auto 0;
        }
      }

      @keyframes slideIn {
        from {
          opacity: 0;
          transform: translateX(-30px);
        }
        to {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }

    h5 {
      font-size: 150px;
      line-height: 150px;

      @media only screen and (max-width: 960px) {
        font-size: 90px;
        line-height: 90px;
      }

      &.blue {
        color: $blue;
      }

      small {
        font-size: 60px;
        line-height: 50px;

        @media only screen and (max-width: 960px) {
            font-size: 40px;
            line-height: 40px;
        }
      }
    }

    h6 {
      font-size: 25px;
      line-height: 30px;
      color: $black;

      @media only screen and (max-width: 960px) {
        width: 80%;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
      }
    }

    &--blue {
      background: $blue;

      h5 {
        color: $volt;
      }

      h6 {
        color: $white;
      }
    }

    &--volt {
        background: $volt;

        h5 {
            color: $blue;
        }

        h6 {
            color: $black;
        }
    }
  }
}