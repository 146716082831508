.bold-richtext {
    font-weight: bolder;
    white-space: nowrap;
}

::selection {
    background: #D6FE00;
    color: #000;
}

html,
body {
    // scroll-behavior: smooth;
}

/* carousel dots */
.makerlab-carousel-dots {
    display: flex;
    justify-content: flex-start;
    margin-top: 25px;
    margin-left: 28px;
  }
  
  .makerlab-carousel-dot {
    width: 10px;
    height: 10px;
    background-color: $greyinactive;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    
    @media screen and (min-width: 960px) {
        &:hover {
            background-color: $white;
        }
    }

    &.active {
        background-color: $white;
    }
  }