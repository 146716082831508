header {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 60px;
    padding: 0 44px;
    z-index: 101;
    background-color: $white;

    @media only screen and (max-device-width: 960px) {
        padding: 0 24px;
    }

    ul {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: block;
            font-size: 14px;
            font-weight: 400;
            color: $mklprimaryblack;
            
            &:not(:last-child) {
                margin-right: 29px;

                @media only screen and (max-device-width: 960px) {
                    display: none;
                }
            }

            &:last-child {
                padding: 0 9px;
                border: 1px solid $mklprimaryblack;
                border-radius: 18px;
                background: $volt;
                color: $mklprimaryblack;
                transition: all 0.3s ease;

                &:hover {
                    background: $blue;
                    color: $white;
                    border-color: $white;
                }
            }

            &:hover {
                cursor: pointer;

                &:not(:last-child) {
                    span,
                    a {
                        border-bottom: 1px solid $blue;
                    }
                }
            }
        }
    }
}